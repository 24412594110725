<template>
  <v-carousel :height="height" show-arrows-on-hover cycle :style="{'background-color': $vuetify.theme.dark ? '#111!important' : '#000000!important'}">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      require: true
    },
    height: {
      type: Number,
      require: false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep  .v-image__image--cover {
  background-size: contain;
}
::v-deep .v-carousel__controls {
  background-color: #111!important;
}
</style>
