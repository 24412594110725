import { render, staticRenderFns } from "./VenueImages.vue?vue&type=template&id=2c83fab3&scoped=true&"
import script from "./VenueImages.vue?vue&type=script&lang=js&"
export * from "./VenueImages.vue?vue&type=script&lang=js&"
import style0 from "./VenueImages.vue?vue&type=style&index=0&id=2c83fab3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.3_b61fd53126d8a76b25cc5244d186282f/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c83fab3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.6.0_9053c641aa08e2db0287c4c8a8df6e99/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VCarousel,VCarouselItem})
