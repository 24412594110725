<template>
  <v-row class="venue-template" align="center" :style="templateStyles">
    <v-col cols="12" class="px-5"
      @click.stop="$route.name === 'VenueEventLiveStream' ?
        goToEventDetail(): undefined"
        :style="$route.name === 'VenueEventLiveStream' && 'cursor: pointer;'"
    >
      <h1>{{venueData.name || venueData.title}}</h1>
      <h3>{{ truncateString(venueData.shortDescription, 30) }}</h3>
    </v-col>
    <!-- Event Live -->
    <template v-if="$route.name === 'VenueEventDetail' ||
      $route.name === 'VenueEventLiveStream'">
      <template v-if="!venueDataIsEventLiveNow">
        <v-col cols="12" class="px-5">
          <v-row
            :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0']"
          >
            <v-col cols="12" class="d-flex align-center justify-space-between
              px-0 pb-0 pt-5"
            >
              <Countdown class="ticket-counter-down"
                :date="venueDataStartDate"
              />
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <!-- El evento ya comenzo -->
        <v-col cols="12"
          @click.stop="hasBoughtTicket ?
          ($route.name === 'VenueEventDetail' ? goToEventLiveStream(): undefined) :
          openTickets()"
          class="d-flex align-center justify-space-between px-5 pt-5"
          :style="($route.name === 'VenueEventDetail' || !hasBoughtTicket)
            && 'cursor: pointer;'"
        >
          <h3>{{ $t('liveEventTitle') }}</h3>
          <v-img
            src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-ticket_live_streaming.svg"
            max-width="26px"
            max-height="20px"
          ></v-img>
        </v-col>
      </template>
      <template v-if="hasBoughtTicket">
        <template v-if="canWatchLive">
          <template v-if="!venueDataIsEventLiveNow">
            <v-col cols="12" class="px-5 pb-5">
              <v-row
                :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0', 'my-2']"
              >
                <v-col cols="12" class="d-flex align-center justify-space-between px-0">
                  <h3>Ya compraste tu boleta. El evento aún no ha comenzado</h3>
                  <v-img
                    src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-ticket_live_streaming.svg"
                    max-width="26px"
                    max-height="20px"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <template v-else>
            <template v-if="$route.name === 'VenueEventDetail'">
              <template v-if="venueData.isOnlineEvent">
                <v-col cols="12" class="d-flex align-center justify-space-between px-5"
                    @click="goToEventLiveStream()"
                >
                  <v-btn
                    color="kbBlue"
                    x-large
                    style="width: 100%;"
                  >
                    VER EL EVENTO EN VIVO
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <!-- El evento no es en linea -->
                <v-col cols="12" class="px-5 pb-5">
                  <v-row
                    :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0', 'my-2']"
                  >
                    <v-col cols="12" class="d-flex align-center justify-space-between px-0">
                      <h3>Debes descargar tu boleta y presentarla en el lugar del evento</h3>
                      <!-- <v-img
                        src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-ticket_live_streaming.svg"
                        max-width="26px"
                        max-height="20px"
                      ></v-img> -->
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </template>
            <template v-if="$route.name === 'VenueEventLiveStream'">
              <template v-if="venueData.isOnlineEvent">
                <!-- El evento esta en vivo y el usuario compro y puede ver el evento -->
                <v-col cols="12" class="px-5 py-0">
                  <v-row
                    :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0']"
                  >
                    <!-- <v-col cols="12"
                      class="d-flex align-center justify-space-between"
                    >
                      <div class="ticket-live-streaming-icon">
                      </div>
                      <div class="text-capitalize text-h5 font-weight-bold">
                        {{ $t("liveEventTitle") }}
                      </div>
                      <div class="text-capitalize text-body-2 font-weight-bold">
                        244 {{ $t("liveEventPeopleAmount") }}
                      </div>
                      <div class="text-body-2 font-weight-bold">
                        |
                      </div>
                      <div class="text-capitalize text-body-2 font-weight-bold">
                        32:02 MIN
                      </div>
                    </v-col> -->
                    <v-col class="px-0 py-0">
                      <!-- <iframe src="https://player.vimeo.com/video/511278457" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
                      <template v-if="!selectedLiveEmbeddedLink">
                        <div class="mb-5" v-html="randomVimeoEmbeddedLink"></div>
                      </template>
                      <template v-else>
                        <div class="mb-5" v-html="selectedLiveEmbeddedLink"></div>
                      </template>
                      <template v-if="venueData.embeddedLinks && Array.isArray(venueData.embeddedLinks) &&
                        this.venueData.embeddedLinks.length > 1"
                      >
                        <template v-if="!showHavingProblemsLiveVideo">
                          <v-btn
                            color="kbBlue"
                            class="mb-5"
                            x-large
                            @click.stop="showHavingProblemsLiveVideo = true"
                            style="width: 100%;"
                          >
                            VER OTRAS SALAS
                          </v-btn>
                        </template>
                        <template v-else>
                          <div>
                            <template v-for="(embedded, idx) in venueData.embeddedLinks">
                              <v-btn
                                :key="idx"
                                color="kbBlue"
                                class="my-2"
                                x-large
                                @click.stop="selectedLiveEmbeddedLink = embedded.url"
                                style="width: 100%;"
                                :disabled="selectedLiveEmbeddedLink === embedded.url"
                                >
                                {{ selectedLiveEmbeddedLink === embedded.url ?
                                  `ESTÁS VIENDO LA SALA ${idx + 1}` :
                                  `ACCEDER A LA SALA ${idx + 1}`
                                }}
                              </v-btn>
                            </template>
                          </div>
                        </template>
                      </template>
                      <!-- <v-btn
                        color="kbBlue"
                        class="mt-4"
                        x-large
                        @click.stop="validateAccessCode(ticket)"
                        style="width: 100%;"
                        >
                        ACCEDER A SALA PREMIUM
                      </v-btn>
                      <v-btn
                        color="kbBlue"
                        class="mt-4"
                        x-large
                        @click.stop="validateAccessCode(ticket)"
                        style="width: 100%;"
                        >
                        COMPRAR PAQUETE ESPECIAL
                      </v-btn>
                      <v-btn
                        color="kbBlue"
                        class="mt-4 mb-4"
                        x-large
                        @click.stop="validateAccessCode(ticket)"
                        style="width: 100%;"
                        >
                        DONAR
                      </v-btn> -->
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <template v-else>
                <!-- El evento no es en linea -->
                <v-col cols="12" class="px-5 pb-5">
                  <v-row
                    :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0', 'my-2']"
                  >
                    <v-col cols="12" class="d-flex align-center justify-space-between px-0">
                      <h3>Debes descargar tu boleta y presentarla en el lugar del evento</h3>
                      <!-- <v-img
                        src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-ticket_live_streaming.svg"
                        max-width="26px"
                        max-height="20px"
                      ></v-img> -->
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <v-col
            cols="12" class="px-5 pb-5"
          >
            <v-row
              :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0', 'my-2', 'px-3']"
            >
              <v-col cols="12" class="d-flex align-center justify-center">
                <h3>{{ 'Ya te encuentras viendo el evento desde otro dispositivo' }}</h3>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </template>
      <template v-else>
        <!-- Valida que hayan ticket con codigo de acceso: -->
        <!-- <template v-if="$route.name === 'VenueEventDetail' && eventHasTicketsWithAccessCode">
          <v-col @click="goToAccessCode"
            cols="12" class="px-5 pb-5" style="cursor: pointer;"
          >
            <v-row
              :class="['ticket-live-streaming', 'kbGray', 'rounded-lg', 'mx-0', 'my-2', 'px-3']"
            >
              <v-col cols="12" class="d-flex align-center justify-space-between">
                <h3>{{ $t('ticketAccesCode') }}</h3>
                <v-img
                  src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-ticket_live_streaming.svg"
                  max-width="26px"
                  max-height="20px"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else> -->
          <!-- El usuario no compro boleta -->
          <v-col cols="12" class="d-flex align-center justify-space-between px-5"
              @click="openTickets()"
          >
            <v-btn
            color="kbBlue"
            x-large
            style="width: 100%;"
            >
            ADQUIERE TUS ENTRADAS
            </v-btn>
          </v-col>
        <!-- </template> -->
      </template>
    </template>

    <template v-if="$route.name === 'VenueEventDetail' ||
      $route.name === 'VenuePlaceDetail'">
      <v-col cols="12" class="venue-template_img pa-5" v-hammer:swipe.left.right="onSwipe">
        <img :src="this.venueData.coverImage" style="width: 100%;" />
          <!-- <BackgroundImage :imageProps="imageProps">
            <v-overlay absolute :z-index="0" :opacity="0">
              <div class="overlay-content">
                <div class="place-action-buttons">
                  <v-row class="place-action-button">
                    <v-btn
                      class=""
                      text
                      fab
                    >
                      <img
                        src="https://storage.googleapis.com/keybe/kb.live/img/action_buttons-scan_qr_code.svg"
                        alt="Interact"
                      >
                    </v-btn>
                    <v-col cols="12" class="place-action-button-span">
                      <span>Interact</span>
                    </v-col>
                  </v-row>
                  <v-row class="place-action-button">
                    <v-btn
                      class=""
                      text
                      fab
                    >
                      <img
                        src="https://storage.googleapis.com/keybe/kb.live/img/place_action_buttons_share.svg"
                        alt="Share"
                      >
                    </v-btn>
                    <v-col cols="12" class="place-action-button-span">
                      <span>Share</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-overlay>
          </BackgroundImage> -->
      </v-col>
      <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
        v-if="venueData.placeRating"
        class="px-5"
      >
        <div>
          <v-icon small v-for="i in venueData.placeRating" :key="`filled-${i}`" color="yellow">mdi-star</v-icon>
          <v-icon small v-for="i in (5 - venueData.placeRating)" :key="`not-filled-${i}`">mdi-star</v-icon>
        </div>
      </v-col>
      <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
        v-if="venueData.priceRating"
        class="px-5"
      >
        <div>
          <v-icon small v-for="i in venueData.priceRating" :key="`filled-${i}`" color="yellow">mdi-currency-usd</v-icon>
          <v-icon small v-for="i in (4 - venueData.priceRating)" :key="`not-filled-${i}`">mdi-currency-usd</v-icon>
        </div>
      </v-col>
      <v-col cols="12" v-if="venueData.workingHours" class="px-5">
        <span :class="[isPlaceOpened ? 'green--text' : 'red--text', 'font-weight-bold']">
          {{ isPlaceOpened ? $t('open') : $t('closed') }}
        </span>
      </v-col>
      <v-col cols="12" class="px-5" v-if="venueData.longDescription || venueData.description">
        <div class="description_container">
          <div class="venue-description" :style="textColor" v-html="venueData.longDescription || venueData.description" />
        </div>
      </v-col>
      <v-col cols="12" class="px-5 d-flex align-center justify-space-between"
          @click="openTickets()"
          v-if="$route.name === 'VenueEventDetail' && !hasBoughtTicket"
      >
        <v-btn
        color="kbBlue"
        x-large
        style="width: 100%;"
        >
        ADQUIERE TUS ENTRADAS
        </v-btn>
      </v-col>
      <!-- TODO: uncomment following code block to enable the feature for all -->
      <!-- <v-col cols="12"
        class="venue-ticket-selector-container px-5"
        v-if="venueData.tickets && Array.isArray(venueData.tickets) && venueData.tickets.length"
        id="access-code"
      >
        <template v-for="(ticket, index) in venueData.tickets">
          <v-row
            :key="index"
            :class="['kbGray', 'rounded-lg', 'mt-2', 'px-3']"
          >
            <v-col cols="12" class="d-flex align-center justify-space-between">
              <h3 v-if="ticket.price > 0">{{formatCurrency(ticket.price) }} - {{ticket.title}}</h3>
              <h3 v-else>{{ticket.title}}</h3>
            </v-col>
            <v-col cols="12"
              v-if="(ticket.accessFrom || venueDataStartDate)"
              class="ticket-counter-container d-flex align-center justify-space-between"
            >
              <Countdown class="ticket-counter-down"
                :date="ticketStartDate((ticket.accessFrom || venueDataStartDate))"
              />
              <v-spacer></v-spacer>
              <div>
                <div class="text-capitalize text-subtitle-2 font-weight-bold">
                  {{ (ticket.accessFrom || venueDataStartDate) | moment("MMMM DD YYYY") }}
                </div>
                <div class="text-subtitle-2 font-weight-bold">
                  {{ (ticket.accessFrom || venueDataStartDate) | moment("dddd hh:mm a") }}
                </div>
              </div>
            </v-col>
            <template v-if="(ticket.accessFrom || venueDataStartDate) && ticket.type.code === 2">
              <v-col cols="12"
                v-if="!ticketErrorCode"
              >
                <v-text-field
                  v-model="accessCode"
                  @input="setSelectedTicket(ticket)"
                  :label="$t('ticketAccesCode')"
                  outlined class="ticket-acess-code"
                  hide-details
                  ref="input-access-code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="ticketErrorCode" @click="focusTextField()">
                <v-text-field v-model="ticketErrorCode" outlined readonly class="ticket-deny-code">
                </v-text-field>
              </v-col>
            </template>
          </v-row>
          <v-row
            v-if="ticket.active"
            :key="`ticket-descr-${index}`"
            :class="['kbGray', 'rounded-lg', 'mt-1', 'px-3']"
          >
            <v-col cols="12">
              <div class="text-subtitle-1">Termina: {{ (ticket.accessUntil || venueData.ends) | moment("dddd DD MMMM hh:mm a") }}</div>
              <div class="text-subtitle-1">{{ venueData.shortDescription }}</div>
            </v-col>
            <template v-if="attendees.length > 0">
              <v-form ref="form">
                <v-row
                  justify="center"
                  v-for="(attendee, idx) in attendees"
                  :key="idx"
                >
                  <div>
                    <AddAttendee :attendee="attendee"
                                :attendee-index="idx"
                                @deleteAttendee="deleteAttendee(idx)"
                                @onAttendeeChange="editAttendee($event, idx)" />
                  </div>
                </v-row>
              </v-form>
            </template>
            <v-row
              style="margin: 0 auto;max-width: 500px"
              v-if="attendees.length === 0"
            >
              <v-col>
                <v-card-text @click="isLoggedIn ? addAttendee() : openKeybeUi()"
                            class="add-attendee-button white--text">
                  {{ $t("addAttendee") }}
                  <v-btn class="my-2 mr-2" outlined small fab color="#11CDEF" style="margin-left: 20px!important">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-row>

          </v-row>
          <v-btn v-if="ticket.type.code === 2"
          :key="`ticket-btn-${index}`"
          color="kbBlue"
          class="mt-4"
          x-large
          @click.stop="validateAccessCode(ticket)"
          style="width: 100%;"
          >
          {{ $t('continue') }}
          </v-btn>
          <p v-if="ticketErrorCode && ticket.type.code === 2" :key="`ticket-error-${index}`" class="red--text mt-4"> {{ticketErrorCode}} </p>
        </template>
      </v-col> -->

      <v-col cols="12"
        class="venue-product-category-tabs-container px-5"
        v-if="venueData.venueDataPlaceCategoriesProducts && Array.isArray(venueData.venueDataPlaceCategoriesProducts) &&
          venueData.venueDataPlaceCategoriesProducts.length"
      >
        <v-tabs
          v-model="selectedProductCategoryTab"
          :show-arrows="false"
          @change="setSelectedProductCategory"
          hide-slider
          background-color="kbGray"
          :color="$vuetify.theme.dark ? '#000000' : '#f1f1f1'"
          active-class="venue-product-category-tab--active"
          class="rounded-t-lg"
        >
          <v-tab v-for="(category, index) in venueData.venueDataPlaceCategoriesProducts" :key="index">
            {{ category.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedProductCategoryTab">
          <v-tab-item v-for="(category, index) in venueData.venueDataPlaceCategoriesProducts" :key="index">
            <v-row v-hammer:swipe.left.right="(event)=> onTabSwipe(event, venueData.venueDataPlaceCategoriesProducts.length, index)" style="flex-direction: column;">
              <v-row v-if="category.description" class="venue-product-item">
                <v-col>
                  <div>{{ category.description }}</div>
                </v-col>
              </v-row>
              <v-row class="venue-product-item" v-for="(product, jindex) in venueData.venueDataProducts" :key="jindex">
                <v-col
                  v-if="product.images && Array.isArray(product.images) && product.images.length"
                  class="flex-grow-0 flex-shrink-0"
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '4'"
                >
                  <v-img
                    :src="product.images[0]"
                    max-width="92px"
                    max-height="92px"
                  ></v-img>
                </v-col>
                <v-col class="flex-grow-1 flex-shrink-0"
                       :cols="$vuetify.breakpoint.mdAndUp ?
                              (product.images.length ? '10' : '12') :
                              (product.images.length ? '8' : '12')">
                  <h3 class="mb-2">{{ product.name }}</h3>
                  <div class="mb-2">{{ product.description }}</div>
                  <!-- <h3 class="mb-2">{{ formatCurrency(product.price) }}</h3> -->
                  <div class="d-flex align-center">
                    <h3>{{ product.price && formatCurrency(product.price) || 0 }}</h3>
                    <h3 v-if="product.numberProducts" class="ml-1"> x {{product.numberProducts}}</h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="product.numberProducts"
                      @click="subtractNumberProducts(product)"
                      class="btn-control-product-number mr-2"
                      color="kbBlue"
                      outlined
                      x-small
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn
                      @click="addNumberProducts(product)"
                      class="btn-control-product-number mr-2"
                      color="kbBlue"
                      outlined
                      x-small
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-btn
                    color="kbBlue"
                    small
                    outlined
                    @click="confirmAddProductToCart(product)"
                  >
                    {{ $t('add') }}
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col cols="12" class="px-5 pb-0">
        <h3 class="pb-4 text-uppercase">
          {{ $t('information') }}
        </h3>
        <v-divider :style="{'border-color': $vuetify.theme.dark ? '#555' : '#f1f1f1'}" />
      </v-col>

      <v-col cols="12" class="px-2">
        <v-row style="color: white" class="mx-0">
          <v-col cols="12" v-if="venueData.placeLocation">
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_address.svg"
                max-width="18px"
                max-height="21px"
                class="mr-4"
              ></v-img>
              <h3 :class="[venueData.placeLocationUrl && 'venue-url']"
                  @click="goTo(venueData.placeLocationUrl)"
                  :style="textColor">
                {{ venueData.placeLocation}}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="venueData.phone">
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_call.svg?current=2"
                max-width="18px"
                max-height="18px"
                class="mr-4"
              ></v-img>
              <h3 :style="textColor"
                  class="venue-url" v-if="venueData.phone.phone"
                  @click="goToCallDialer(venueData.phone.phone, venueData.phone.number)"
              >
                {{ `+${venueData.phone.number} ${venueData.phone.phone}` }}
              </h3>
              <h3 :style="textColor"
                  class="venue-url" v-else
                  @click="goToCallDialer(venueData.phone)"
              >
                {{ venueData.phone }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="venueData.priceAverage"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_price_avge.svg"
                max-width="15px"
                max-height="26px"
                class="mr-4"
              ></v-img>
              <h3 :style="textColor">
                {{ venueData.priceAverage }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="venueData.diningStyle"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_dining_style.svg"
                max-width="20px"
                max-height="14px"
                class="mr-4"
              ></v-img>
              <h3 :style="textColor">
                {{ $t(venueData.diningStyle) }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="venueData.brunchSchedules ||
            venueData.lunchSchedules ||
            venueData.happyHourSchedules ||
            venueData.dinnerSchedules"
          >
            <v-row class="mx-0 flex-nowrap" v-if="venueData.brunchSchedules &&
              Array.isArray(venueData.brunchSchedules) && venueData.brunchSchedules.length">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_meals-hours.svg"
                max-width="18px"
                max-height="18px"
                class="mt-2 mr-4"
              ></v-img>
              <div>
                <v-col class="pa-0 py-1">
                  <h3 :style="textColor">
                    {{ $t('brunch') }}
                  </h3>
                </v-col>
                <template v-for="(schedule, index) in venueData.brunchSchedules">
                  <v-col class="px-0 py-1" :key="index">
                    <h4 :style="textColor">
                      {{ `${$t(schedule.fromDay)}` }}{{ `${schedule.untilDay && ' - '}${$t(schedule.untilDay)}` }} {{ [schedule.fromTime, "HH:mm" ] | moment("h:mm a") }} - {{ [schedule.untilTime, "HH:mm" ] | moment("h:mm a") }}
                    </h4>
                  </v-col>
                </template>
              </div>
            </v-row>
            <v-row class="mx-0 flex-nowrap" v-if="venueData.lunchSchedules &&
                Array.isArray(venueData.lunchSchedules) && venueData.lunchSchedules.length">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_meals-hours.svg"
                max-width="18px"
                max-height="18px"
                class="mt-2 mr-4"
              ></v-img>
              <div>
                <v-col class="pa-0 py-1">
                  <h3 :style="textColor">
                    {{ $t('lunch') }}
                  </h3>
                </v-col>
                <template v-for="(schedule, index) in venueData.lunchSchedules">
                  <v-col class="px-0 py-1" :key="index">
                    <h4 :style="textColor">
                      {{ `${$t(schedule.fromDay)}` }}{{ `${schedule.untilDay && ' - '}${$t(schedule.untilDay)}` }} {{ [schedule.fromTime, "HH:mm" ] | moment("h:mm a") }} - {{ [schedule.untilTime, "HH:mm" ] | moment("h:mm a") }}
                    </h4>
                  </v-col>
                </template>
              </div>
            </v-row>
            <v-row class="mx-0 flex-nowrap" v-if="venueData.happyHourSchedules &&
                Array.isArray(venueData.happyHourSchedules) && venueData.happyHourSchedules.length">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_meals-hours.svg"
                max-width="18px"
                max-height="18px"
                class="mt-2 mr-4"
              ></v-img>
              <div>
                <v-col class="pa-0 py-1">
                  <h3 :style="textColor">
                    {{ $t('happyHour') }}
                  </h3>
                </v-col>
                <template v-for="(schedule, index) in venueData.happyHourSchedules">
                  <v-col class="px-0 py-1" :key="index">
                    <h4 :style="textColor">
                      {{ `${$t(schedule.fromDay)}` }}{{ `${schedule.untilDay && ' - '}${$t(schedule.untilDay)}` }} {{ [schedule.fromTime, "HH:mm" ] | moment("h:mm a") }} - {{ [schedule.untilTime, "HH:mm" ] | moment("h:mm a") }}
                    </h4>
                  </v-col>
                </template>
              </div>
            </v-row>
            <v-row class="mx-0 flex-nowrap" v-if="venueData.dinnerSchedules &&
                Array.isArray(venueData.dinnerSchedules) && venueData.dinnerSchedules.length">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_meals-hours.svg"
                max-width="18px"
                max-height="18px"
                class="mt-2 mr-4"
              ></v-img>
              <div>
                <v-col class="pa-0 py-1">
                  <h3 :style="textColor">
                    {{ $t('dinner') }}
                  </h3>
                </v-col>
                <template v-for="(schedule, index) in venueData.dinnerSchedules">
                  <v-col class="px-0 py-1" :key="index">
                    <h4 :style="textColor">
                      {{ `${$t(schedule.fromDay)}` }}{{ `${schedule.untilDay && ' - '}${$t(schedule.untilDay)}` }} {{ [schedule.fromTime, "HH:mm" ] | moment("h:mm a") }} - {{ [schedule.untilTime, "HH:mm" ] | moment("h:mm a") }}
                    </h4>
                  </v-col>
                </template>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="venueData.acceptedPaymentMethods && Array.isArray(venueData.acceptedPaymentMethods) && venueData.acceptedPaymentMethods.length"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_accepted_payment_methods.svg"
                max-width="20px"
                max-height="16px"
                class="mr-4"
              ></v-img>
              <h3 class="text-capitalize" :style="textColor">
                {{ acceptedPaymentMethods }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="venueData.dressCode && Array.isArray(venueData.dressCode) && venueData.dressCode.length"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_dress_code.svg"
                max-width="20px"
                max-height="18px"
                class="mr-4"
              ></v-img>
              <h3 class="text-capitalize" :style="textColor">
                {{ dressCodes }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="venueData.parkingType && Array.isArray(venueData.parkingType) && venueData.parkingType.length"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_parking_info.svg"
                max-width="24px"
                max-height="12px"
                class="mr-4"
              ></v-img>
              <h3 class="text-capitalize" :style="textColor">
                {{ parkingTypes }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12"
            v-if="(venueData.hasExternalEventUrl && venueData.externalEventUrl) || venueData.website" @click="goTo(venueData.externalEventUrl || venueData.website)"
          >
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                src="https://storage.googleapis.com/keybe/kb.live/img/venue_template-details_website.svg"
                max-height="20px"
                max-width="20px"
                class="mr-4"
              ></v-img>
              <h3 class="venue-url" :style="textColor">
                {{ venueData.externalEventUrl || venueData.website }}
              </h3>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="venueData.tags && Array.isArray(venueData.tags) && venueData.tags.length > 0"
            class="d-flex justify-space-around flex-wrap"
          >
            <v-chip
              class="ma-1"
              label
              outlined
              :color="iconColor"
              v-for="(tag, idx) in venueData.tags" :key="idx"
            >
              {{ tag }}
            </v-chip>
          </v-col>
          <v-col cols="12"
            class="d-flex justify-space-around flex-wrap"
            v-if="venueData.socialLinks && Array.isArray(venueData.socialLinks) && venueData.socialLinks.length"
          >
            <template v-for="(network, index) in venueData.socialLinks">
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-if="network && network.provider === 'facebook'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-facebook-f</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'facebook-calendar'"
                @click="goTo(network.url)"
              >
                <v-icon>far fa-calendar-alt</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'google'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-google</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'google-maps'"
                @click="goTo(network.url)"
              >
                <v-icon>fas fa-map-marker</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'instagram'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-instagram</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'linkedin'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-linkedin</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'soundcloud'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-soundcloud</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'spotify'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-spotify</v-icon>
              </v-btn>
              <v-btn
               :color="iconColor"
                class="px-0 my-1 mr-2"
                fab
                small
                outlined
                :key="index"
                v-else-if="network && network.provider === 'twitter'"
                @click="goTo(network.url)"
              >
                <v-icon>fab fa-twitter</v-icon>
              </v-btn>
            </template>
          </v-col>
          <v-col cols="12" v-if="this.venueData.placeLocationReference">
            <iframe
              width="100%"
              height="450"
              frameborder="0" style="border:0"
              :src="placeLocationUrl" allowfullscreen>
            </iframe>
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col cols="12">
        <v-col cols="12" v-if="this.venueData.starts">
          <Countdown :date="venueDataStartDate" />
        </v-col>
        <div class="" v-if="!isLoggedIn">
          <v-col class="text-center ">
            {{ $t("pleaseLoginIfYouBoughtATicket") }}
          </v-col>

          <v-col class="text-center">
            <v-btn @click="openKeybeUi()" color="#0BD6DC" x-large>
              {{ $t("login") }}
            </v-btn>
          </v-col>
        </div>
        <div v-else-if="$route.params.id === '5fd0cc5c8c0ea9259bf59e49' && hasBoughtTicket">
          <div>
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/491356041" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
          </div>
        </div>
        <div v-else>
            <v-col class="text-center ">
                {{ $t("youDontHaveEntracesYet") }}
            </v-col>
            <v-col class="text-center">
              <v-btn @click="openTickets()" color="#0BD6DC" x-large>
                {{ $t("entrances") }}
              </v-btn>
            </v-col>

        </div>
      </v-col> -->
      <!-- <v-col cols="12"
        v-if="Array.isArray(venueData.tickets) && venueData.tickets.length"
        class="pt-5"
        style="background-color: #222; padding: 20px">
        <div>
          <SelectTicketPrice
            color="#11CDEF"
            class="white--text ma-0"
            v-model="selectedTicket"
            :tickets="venueData.tickets"
            v-if="venueData.tickets"
          />
        </div>
      </v-col> -->
      <!-- <v-col cols="12" v-if="venueData.venueDataPlaceCategoriesProducts && Array.isArray(venueData.venueDataPlaceCategoriesProducts) &&
        venueData.venueDataPlaceCategoriesProducts.length"
        class="pb-0"
      >
        <VenueProductsCategories
          class="carousel"
          :items="venueData.venueDataPlaceCategoriesProducts"/>
      </v-col>
      <v-col cols="12" v-if="selectedProductCategoryId && venueData.venueDataProducts && Array.isArray(venueData.venueDataProducts)"
        class="pt-0"
      >
        <VenueProducts
          class="carousel"
          :items="venueData.venueDataProducts"/>
      </v-col> -->
      <v-col cols="12" v-if="carouselItems.length">
        <v-row>
          <VenueImages
            class="carousel"
            :items="carouselItems"/>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment'
import windowSize from '@/mixins/windowSize'
import formatString from '@/mixins/formatString'
import formatCurrency from '@/mixins/formatCurrency'
import VenueImages from '@/components/Carousel/VenueImages'
// import VenueProductsCategories from '@/components/Carousel/VenueProductsCategories'
// import VenueProducts from '@/components/Carousel/VenueProducts'
// import SelectTicketPrice from '@/components/SelectTicketPrice/Index'
import Countdown from '@/components/Countdown/Countdown'
import { mapState } from 'vuex'
// import ApprovedDialog from '@/components/Dialogs/ApprovedDialog'

export default {
  components: {
    // BackgroundImage,
    VenueImages,
    // VenueProductsCategories,
    // VenueProducts,
    // SelectTicketPrice,
    Countdown
    // ApprovedDialog
  },
  mixins: [windowSize, formatString, formatCurrency],
  async created () {
    window.addEventListener('onLogout', () => {
      this.attendees = []
    })
  },
  mounted () {
    const { params, name } = this.$route
    const { id } = params
    if ((name === 'VenueEventDetail' || name === 'VenueEventLiveStream') && id) {
      if (this.isLoggedIn) {
        this.$store.dispatch('events/getHasBoughtTicket', id)
      }
      window.addEventListener('onLoginSuccess', () => {
        this.$store.dispatch('events/getHasBoughtTicket', id)
      })
      this.scheduleCheckEventIsLiveNow()
    }
  },
  data () {
    return {
      attendees: [],
      selectedProductCategoryTab: 0,
      accessCode: '',
      showApprovedDialog: false,
      ticketErrorCode: '',
      showHavingProblemsLiveVideo: false,
      selectedLiveEmbeddedLink: '',
      swipe: false
    }
  },
  computed: {
    ...mapState({
      selectedProductCategoryId: state => state.places.selectedProductCategoryId,
      isLoggedIn: state => state.authKeybe.isLoggedIn,
      userInfo: state => state.authKeybe.userInfo,
      productCartItems: state => state.shoppingCart.productCartItems,
      canWatchLive: state => state.events.canWatchLive
    }),
    selectedProductCategoryId: {
      get () {
        return this.$store.state.places.selectedProductCategoryId
      },
      set (value) {
        this.$store.commit('places/SET_SELECTED_PLACE_PRODUCTS_CATEGORY_ID', value)
      }
    },
    hasBoughtTicket: {
      get () {
        return this.$store.state.events.hasBoughtTicket
      },
      set (value) {
        this.$store.commit('events/SET_HAS_BOUGHT_TICKET_FOR_EVENT', value)
      }
    },
    templateStyles () {
      let templateStyles = {}
      if (this.$route.params.id === 'ghost-kitchens') {
        templateStyles.fontFamily =
          "'NunitoSans', sans-serif"
      }
      return templateStyles
    },
    iconColor () {
      return this.$vuetify.theme.dark ? '#ffffff' : '#8898aa'
    },
    textColor () {
      return { color: this.$vuetify.theme.dark ? '#ffffff' : '#000000' }
    },
    imageProps () {
      return {
        url: this.venueData.coverImage,
        height: this.$vuetify.breakpoint.smAndDown ? 'calc(100vw - 40px)' : 'calc(768px - 40px)',
        borderRadius: '0px 0px 0px 0px',
        backgroundSize: 'cover',
        backgroundColor: null,
        extra: {
          position: 'relative'
        }
      }
    },
    carouselItems () {
      if (Array.isArray(this.venueData.images) && this.venueData.images.length) {
        return this.venueData.images.map((image) => { return { src: image.url } })
      }
      return []
    },
    selectedTicket: {
      get () {
        return this.$store.state.storedStates.selectedTicket
      },
      set (value) {
        this.$store.commit('storedStates/SET_SELECTED_TICKET', value)
      }
    },
    venueDataStartDate () {
      let venueDataStartDate = moment()
      if (this.venueData.starts) {
        venueDataStartDate = moment(this.venueData.starts)
        // console.log('this.venueData.starts')
        // console.log(this.venueData.starts)
      }
      // console.log('timeZone')
      // console.log(this.venueData.timeZone)
      let timeZone
      if (this.venueData.timeZone && this.venueData.timeZone.label) {
        timeZone = this.venueData.timeZone.label
        venueDataStartDate = venueDataStartDate.tz(timeZone, true)
      }
      // console.log('venueDataStartDate1')
      // console.log(venueDataStartDate.format('YYYY-MM-DD hh:mm:ss A Z'))
      return venueDataStartDate
    },
    venueDataIsEventLiveNow () {
      let venueDataIsEventLiveNow
      let now = moment()
      if (this.venueDataStartDate < now) {
        venueDataIsEventLiveNow = true
      }
      // console.log('venueDataIsEventLiveNow')
      // console.log(venueDataIsEventLiveNow)
      return venueDataIsEventLiveNow
    },
    placeLocationUrl () {
      // console.log('this.venueData.placeLocationReference')
      // console.log(this.venueData.placeLocationReference)
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAh4gwhfQNQ11T8jsWWunt8wzUOwiB6vTw&q=place_id:${this.venueData.placeLocationReference}`
    },
    acceptedPaymentMethods () {
      let acceptedPaymentMethods = ''
      if (this.venueData.acceptedPaymentMethods && Array.isArray(this.venueData.acceptedPaymentMethods)) {
        for (let i = 0; i < this.venueData.acceptedPaymentMethods.length - 1; i++) {
          acceptedPaymentMethods += this.$t(this.venueData.acceptedPaymentMethods[i]) + ', '
        }
        acceptedPaymentMethods += this.$t(this.venueData.acceptedPaymentMethods[this.venueData.acceptedPaymentMethods.length - 1])
      }
      return acceptedPaymentMethods
    },
    dressCodes () {
      let dressCodes = ''
      if (this.venueData.dressCode && Array.isArray(this.venueData.dressCode)) {
        for (let i = 0; i < this.venueData.dressCode.length - 1; i++) {
          dressCodes += this.$t(this.venueData.dressCode[i]) + ', '
        }
        dressCodes += this.$t(this.venueData.dressCode[this.venueData.dressCode.length - 1])
      }
      return dressCodes
    },
    parkingTypes () {
      let parkingTypes = ''
      if (this.venueData.parkingType && Array.isArray(this.venueData.parkingType)) {
        for (let i = 0; i < this.venueData.parkingType.length - 1; i++) {
          parkingTypes += this.$t(this.venueData.parkingType[i]) + ', '
        }
        parkingTypes += this.$t(this.venueData.parkingType[this.venueData.parkingType.length - 1])
      }
      return parkingTypes
    },
    isPlaceOpened () {
      let isPlaceOpened
      try {
        if (this.venueData.workingHours && this.venueData.workingHours.days && Array.isArray(this.venueData.workingHours.days) &&
          this.venueData.workingHours.days.length) {
          // console.log('this.venueData.workingHours')
          // console.log(this.venueData.workingHours)
          let dateOffset = moment()
          let timeZone
          if (this.venueData.timeZone) {
            timeZone = this.venueData.timeZone.label
            // console.log('timeZone')
            // console.log(timeZone)
            dateOffset = dateOffset.tz(timeZone)
          }

          let dateOffsetDay = dateOffset.day()
          // console.log(moment().tz('Pacific/Niue').format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(dateOffset.local().tz('Pacific/Niue').format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(dateOffset.format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(this.venueData.workingHours.days[dateOffsetDay])
          if (!this.venueData.workingHours.days[dateOffsetDay].isClosed) {
            if (Array.isArray(this.venueData.workingHours.days[dateOffsetDay].schedules)) {
              if (!this.venueData.workingHours.days[dateOffsetDay].schedules.length) isPlaceOpened = true
              for (let index = 0; index < this.venueData.workingHours.days[dateOffsetDay].schedules.length; index++) {
                const schedule = this.venueData.workingHours.days[dateOffsetDay].schedules[index]
                // console.log('schedule')
                // console.log(schedule)
                if (schedule.from && schedule.until) {
                  let from = moment(schedule.from, 'HH:mm')
                  let until = moment(schedule.until, 'HH:mm')
                  if (timeZone) {
                    from = from.tz(timeZone)
                    until = until.tz(timeZone)
                  }
                  // console.log(from.format('YYYY-MM-DD hh:mm:ss A Z'))
                  // console.log(until.format('YYYY-MM-DD hh:mm:ss A Z'))
                  // console.log(dateOffset.isBetween(from, until, 'minute'))
                  if (dateOffset.isBetween(from, until, 'minute')) isPlaceOpened = true
                }
              }
            }
          }
        }
      } catch (error) {
        // console.error(error)
      }
      return isPlaceOpened
    },
    // eventHasTicketsWithAccessCode () {
    //   let eventHasTicketsWithAccessCode
    //   if (this.venueData.tickets && Array.isArray(this.venueData.tickets) && this.venueData.tickets.length) {
    //     let index = this.venueData.tickets.findIndex((ticket) => ticket.type.code === 2)
    //     if (index >= 0) eventHasTicketsWithAccessCode = true
    //   }
    //   console.log('eventHasTicketsWithAccessCode')
    //   console.log(eventHasTicketsWithAccessCode)
    //   return eventHasTicketsWithAccessCode
    // },
    randomVimeoEmbeddedLink () {
      let randomVimeoEmbeddedLink
      if (this.venueData.embeddedLinks && Array.isArray(this.venueData.embeddedLinks) && this.venueData.embeddedLinks.length) {
        let vimeoVideos = this.venueData.embeddedLinks.filter((embedded) => embedded.provider === 'vimeo')
        // console.log(vimeoVideos)
        if (vimeoVideos.length) {
          const randNum = Math.floor(Math.random() * Math.floor(vimeoVideos.length))
          // console.log('randNum')
          // console.log(randNum)
          randomVimeoEmbeddedLink = vimeoVideos[randNum].url
        }
      }
      // console.log(randomVimeoEmbeddedLink)
      return randomVimeoEmbeddedLink
    }
  },
  methods: {
    onSwipe (evt) {
      this.swipe = true
      if (evt.direction === 4) {
        this.$emit('show-prev-venue')
      }
      if (evt.direction === 2) {
        this.$emit('show-next-venue')
      }
    },
    onTabSwipe (evt, items, index) {
      if (evt.direction === 4 && index >= 1) {
        this.setSelectedProductCategory(index - 1)
      }
      if (evt.direction === 2 && index < items - 1) {
        this.setSelectedProductCategory(index + 1)
      }
    },
    openKeybeUi () {
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
    },
    goToCallDialer (phone, country = '57') {
      if (phone) {
        window.open(`tel:+${country}${phone}`, '_blank')
      }
    },
    openTickets () {
      this.$router.push({
        name: 'VenueEventPurchase',
        params: { id: this.$route.params.id }
      })
    },
    setSelectedProductCategory (index) {
      // this.selectedProductCategoryIdx = index
      this.selectedProductCategoryId = this.venueData.venueDataPlaceCategoriesProducts[index]._id
      // console.log('this.selectedProductCategoryId')
      // this.selectedProductCategoryName = this.items[index].name
      this.$store.dispatch('places/getSelectedPlaceProducts')
    },
    addNumberProducts (product) {
      const productIdx = this.productCartItems.findIndex(el => el.id === product.id)
      this.$set(product, 'numberProducts', (product &&
        product.numberProducts && product.numberProducts + 1) || 1)
      if (productIdx >= 0) {
        this.$store.commit('shoppingCart/SET_PRODUCT_CART_ITEM', { index: productIdx, product })
      } else {
        this.$store.commit('shoppingCart/ADD_PRODUCT_TO_CART', product)
      }
    },
    subtractNumberProducts (product) {
      const productIdx = this.productCartItems.findIndex(el => el.id === product.id)
      this.$set(product, 'numberProducts', (product &&
        product.numberProducts && product.numberProducts - 1) || 0)
      // let newNumberProducts = (product.numberProducts && product.numberProducts - 1) || 0
      if (product.numberProducts === 0) {
        this.$store.commit('shoppingCart/REMOVE_PRODUCT_FROM_CART', productIdx)
      } else {
        // console.log('productIdx')
        // console.log(productIdx)
        // console.log('product.numberProducts')
        // console.log(product.numberProducts)
        // // console.log(this.productCartItems)
        if (productIdx >= 0) {
          this.$store.commit('shoppingCart/SET_PRODUCT_CART_ITEM', { index: productIdx, product })
        }
      }
    },
    confirmAddProductToCart (product) {
      // goto toppings selector
      this.$router.push({
        name: 'VenuePlaceProductToppingsSelector',
        params: {
          id: this.$route.params.id,
          productId: product._id
        }
      })
    },
    goToEventDetail () {
      this.$router.push({
        name: 'VenueEventDetail',
        params: { id: this.$route.params.id }
      })
    },
    goToEventLiveStream () {
      this.$router.push({
        name: 'VenueEventLiveStream',
        params: { id: this.$route.params.id }
      })
    },
    goTo (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    goToAccessCode () {
      this.$vuetify.goTo('#access-code')
      let inputAccessCode = this.$refs['input-access-code']
      // console.log(inputAccessCode)
      if (inputAccessCode && inputAccessCode[0]) {
        inputAccessCode[0].focus()
      }
    },
    scheduleCheckEventIsLiveNow () {
      let now = moment().valueOf()
      let timeout = this.venueDataStartDate.diff(now)

      if (now < this.venueDataStartDate.valueOf()) {
        // console.log('Event hasnt started, setting timeout')
        setTimeout(() => {
          // Reload page
          window.location.reload()
        }, timeout)
      } else {
        // console.log('event started!')
      }
    },
    // validateAccessCode (ticket) {
    //   if (!this.isLoggedIn) {
    //     return this.openKeybeUi()
    //   }
    //   // console.log(this.attendees[0].phone)

    //   if (this.attendees[0] && this.attendees[0].name && this.attendees[0].email && this.attendees[0].phone && this.attendees[0].phone.phone && this.attendees[0].phone.valid === true) {
    //     if (!this.accessCode) {
    //       return notifications.error(this.$t('mustInsertAccessCode'))
    //     } else {
    //       const attendees = this.attendees
    //       try {
    //         this.$store.dispatch('events/setEventPurchase', {
    //           ticketId: ticket._id,
    //           attendees: attendees,
    //           code: this.accessCode
    //         })
    //           .then((response) => {
    //             // console.log('response')
    //             // console.log(response)
    //             if (ticket.type.code === 2) {
    //               this.hasBoughtTicket = true
    //               this.$vuetify.goTo(0)
    //               this.accessCode = ''
    //               this.attendees = []
    //             }
    //           })
    //           .catch((error) => {
    //             this.ticketErrorCode = error.message
    //             if (error.errors && Array.isArray(error.errors) && error.errors.length) {
    //               this.ticketErrorCode = error.errors[0].msg
    //             }
    //           })
    //       } catch (error) {
    //         notifications.error(error.message)
    //       }
    //     }
    //   } else {
    //     return notifications.error('DEBES INGRESAR TODOS LOS CAMPOS')
    //   }
    // },
    setSelectedTicket (ticket) {
      this.$set(ticket, 'active', this.accessCode && this.accessCode.length >= 6)
    },
    setUserInfoAsAttendee () {
      const { name = '', email = '', phone: phoneNumber = '' } = this.userInfo
      const phone = {
        phone: phoneNumber
      }
      this.addAttendee({ name, email, phone })
    },
    addAttendee (attendee = { name: '', email: '', phone: { phone: '' } }) {
      this.attendees.push({ ...attendee, totalTickets: 1 })
    },
    deleteAttendee (index) {
      this.attendees.splice(index, 1)
    },
    editAttendee (attendeeInfo, index) {
      const { key, value } = attendeeInfo
      this.attendees[index][key] = value
    },
    focusTextField () {
      this.ticketErrorCode = ''
    }
  },
  watch: {
    selectedTicket () {
      // console.log('selectedTicket!')
      // console.log(this.selectedTicket)
      this.openTickets()
    },
    userInfo (val) {
      if (val) {
        this.setUserInfoAsAttendee()
      }
    }
  },
  props: {
    venueData: {
      required: true,
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.venue-template {
  background-color: var(--v-kbGray-base);
  // .place-action-buttons {
  //   width: 75px;
  //   position: absolute;
  //   bottom: 0;
  //   right: 20px;
  //   display: block;
  //   .place-action-button {
  //     justify-content: center;
  //     .place-action-button-span {
  //       display: flex;
  //       justify-content: center;
  //     }
  //   }
  // }
  .ticket-live-streaming {
      // border-color: #f53c56 !important;
      // border-width: 1px;
      // border-style: solid;
      .ticket-live-streaming-icon {
        width: 20px;
        height: 20px;
        background-color: #f53c56;
        border-radius: 50%;
      }
    }
  // .venue-ticket-selector-container {
  //   .ticket-counter-container {
  //     .ticket-counter-down {
  //       height: 100%;
  //     }
  //   }
  //   .ticket-acess-code {
  //     ::v-deep .v-input__control {
  //       .v-input__slot {
  //         fieldset {
  //           border-style: solid;
  //           border-width: 1px;
  //           border-color: var(--v-kbBlue-base) !important;
  //           color: var(--v-kbBlue-base) !important;
  //           &:hover {
  //             border-width: 2px;
  //           }
  //         }
  //       }
  //       .v-text-field__slot {
  //         .v-label {
  //           color: var(--v-kbBlue-base) !important;
  //           font-size: 15px;
  //         }
  //         input {
  //           text-transform: uppercase;
  //           color: var(--v-kbBlue-base) !important;
  //         }
  //       }
  //     }
  //   }
  //   .ticket-deny-code {
  //     ::v-deep .v-input__control {
  //       .v-input__slot {
  //         fieldset {
  //           border-style: solid;
  //           border-width: 1px;
  //           border-color: var(--v-kbRed-base) !important;
  //           color: var(--v-kbBlue-base) !important;
  //           &:hover {
  //             border-width: 2px;
  //           }
  //         }
  //       }
  //       .v-text-field__slot {
  //         input {
  //           color: var(--v-kbRed-base) !important;
  //           text-transform: uppercase;
  //         }
  //       }
  //     }
  //   }
  //   .add-attendee-button{
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
  .venue-product-category-tabs-container {
    .v-tab:not(.v-tab--active) {
      // color: var(--v-primary-base) !important;
    }

    .v-window-item {
      div.venue-product-item {
        background-color: var(--v-kbGray-base);
        margin: 1px 0;
        .btn-control-product-number {
          min-width: 26px;
          padding: 0;
        }
      }
    }
  }
}
</style>
<style scoped>
@font-face {
  font-family: 'MyriadPro';
  src: url("https://storage.googleapis.com/keybe/kb.live/fonts/MyriadPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'NunitoSans';
  src: url('https://storage.googleapis.com/keybe/kb.live/fonts/NunitoSans-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'NunitoSans';
  src: url('https://storage.googleapis.com/keybe/kb.live/fonts/NunitoSans-Bold.ttf') format("truetype");
  font-weight: bold;
}
.venue-url {
  cursor: pointer;
  line-break: anywhere;
}
.overlay-content {
  width: 100%;
  height: 100%;
  position: relative;
  /* position: absolute;
  bottom: 0; */
  display: flex;
}
/* .place-detail_title {
  position: absolute;
  bottom: 0;
  margin: 20px;
  width: 250px;
}
.place-detail_title h1 {
  display: block;
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0px;
  font-family: Montserrat;
}
.place-detail_title p {
  display: block;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
} */

/* .description_container {
  color: #ffffff;
} */

.event-detail-description_container h3 {
  padding: 5px;
}
.venue-description {
  text-align: left;
  /* font-weight: bold; */
  font-size: 16px;
}

.venue-product-category-tab--active {
  color: var(--v-kbBlue-base) !important;
  font-weight: bold;
  border-bottom-color: var(--v-kbBlue-base) !important;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.venue-product-category-tab--active:before {
  opacity: 0.08 !important;
}

.carousel {
  padding-left: 0.5em;
}
/* sm */
@media only screen and (max-width: 600px) {
  .venue-template_img {
    margin-top: -0.7em;
    z-index: 0;
  }

  .event-price {
    min-height: 200px;
  }

  .carousel {
    padding-left: 0;
  }
}
/* md */
@media only screen and (max-width: 960px) and (min-width: 600px) {
}
@media only screen and (max-width: 1264px) {
}
</style>
<style>
/** Have a full width overlay */
.v-overlay__content {
  height: 100%;
  width: 100%;
}
/** Hide slider of v-tabs */
.v-slide-group__prev {
  display: none !important;
}
/* .v-tabs-bar__content {
  flex-wrap: wrap;
  width: 100%;
}
div.v-tabs-bar {
  height: auto;
} */
/** Set color for ocerlay */
.v-overlay__scrim {
  background-color: #111111 !important;
}
</style>
